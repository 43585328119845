import React from "react";
import { Table } from "reactstrap";
import Grid from "../../components/Grid.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerContacts = ({ token, port, customerId }) => {
  const selectAPI = `customer-contacts-s?id=${customerId}`;
  const updateAPI = 'customer-contacts-u';
  const primarySort = 'firstName';
  const filterableFields = [
    'phoneNumber', 
    'firstName', 
    'lastName', 
    'title',
    'emailAddress', 
    'customerContactNotes',
  ];
  const module="customers-customer-contacts";
  const title="Edit Customer Contacts";
  const modalSize="extra-wide-modal";

  const editFormObject = (row) => {
    return {
      id: row.id,
      customerId: row.customerId, 
      phoneNumber: row.phoneNumber,
      firstName: row.firstName,
      lastName: row.lastName,
      title: row.title,
      address: row.address,
      city: row.city,
      state: row.state,
      zip: row.zip,
      emailAddress: row.emailAddress,
      customerContactNotes: row.customerContactNotes,
      active: row.active,
      new: false,
    }
  }

  const newFormObject = () => {
    return {
      id: null,
      customerId: customerId,
      phoneNumber: '',
      firstName: '',
      lastName: '',
      title: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      emailAddress: '',
      customerContactNotes: '',
      active: -1, 
      new: true,
    }
  }

  return (
    <Grid 
      api={{token: token, port: port}} 
      editFormObject={editFormObject} 
      newFormObject={newFormObject} 
      selectAPI={selectAPI}
      updateAPI={updateAPI}
      primarySort={primarySort}
      filterableFields={filterableFields}
      module={module}
      title={title}
      modalSize={modalSize}
    />
  )
}

const CustomerContactsTable = ({ sortBy, rows, toggle }) => {
  return (
    <div className="tableFixHead">
      <Table className="table table-striped" hover>
        <thead>
          <tr>
            <th onClick={() => sortBy('firstName', true)}>First Name <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('lastName', true)}>Last Name <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('title', true)}>Title <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('phoneNumber', true)} style={{"whiteSpace": "nowrap"}}>Phone Number <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('emailAddress', true)}>Email Address <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('customerContactNotes', true)}>Notes <FontAwesomeIcon icon="search" className="mr-2" /></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {return (
            <tr key={i}>
              <td onClick={() => toggle(row, 'open')}>{row.firstName}</td>
              <td onClick={() => toggle(row, 'open')}>{row.lastName}</td>
              <td onClick={() => toggle(row, 'open')}>{row.title}</td>
              <td onClick={() => toggle(row, 'open')}>{row.phoneNumber}</td>
              <td><a href={`mailto:${row.emailAddress}`}>{row.emailAddress}</a></td>
              <td onClick={() => toggle(row, 'open')}>{row.customerContactNotes}</td>
            </tr>
          )})}
        </tbody>
      </Table>
    </div>
  )
}

export { CustomerContacts, CustomerContactsTable }