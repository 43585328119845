import _ from 'lodash';
import moment from 'moment';
 
const pdfDocument = (currentRows, companyName, logo) => {

    const getHeader = () => {
        const widths = (logo) ? [80, '*'] : ['*'];
        const body = (logo) ? [{image: 'logo', width: 80}, {text: `Customer History Report for ${companyName}`, style: 'header'}] : [{text: `Customer History Report for ${companyName}`, style: 'header'}];
        return {
            table: {
                widths: widths,
                body: [
                    body
                ]
            },
            layout: 'noBorders',

        }
    }

    const getContent = (rows) => {
        const header = [
            {text: 'Entry Date', style: {fillColor: '#999999'}}, 
            {text: 'Activity', style: {fillColor: '#999999'}},
            {text: 'Follow Up / Date', colSpan: 2, style: {fillColor: '#999999', alignment: 'center'}},
            {text: '', style: {fillColor: '#999999'}},
            {text: 'Completed / Date', colSpan: 2, style: {fillColor: '#999999', alignment: 'center'}},
            {text: '', style: {fillColor: '#999999'}},
            {text: 'Notes', style: {fillColor: '#999999'}},
        ];

        let body = [];
    
        body.push(header); 
    
        _.forEach(rows, r => {
            let row = [];

            row.push(r.dateOfActivity && r.dateOfActivity !== '0000-00-00 00:00:00.000000' ? moment.utc(r.dateOfActivity).format('l') : '');
            row.push(r.activityTypeDescription);
            row.push(r.followUpRequired === -1 ? 'YES' : 'NO');
            row.push(r.followUpDate && r.followUpDate !== '0000-00-00 00:00:00.000000' ? moment.utc(r.followUpDate).format('l') : '');
            row.push(r.followUpComplete === -1 ? 'YES' : 'NO');
            row.push(r.completedDate && r.completedDate !== '0000-00-00 00:00:00.000000' ? moment.utc(r.completedDate).format('l') : '');
            row.push(r.customerHistoryNote);

            body.push(row);
        });
    
        return [
            {
                table: {
                    widths: ['auto', '*', 'auto', 'auto', 'auto', 'auto', '*'],
                    body: body,
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return (rowIndex % 2 === 0) ? '#E6E6E6' : null;
                    }
                },
            }
        ]     
    }

    const getStyles = () => {
        return {
            header: {
                fontSize: 20,
                alignment: 'center',  
                bold: true, 
                margin: [20,20,20,20],     
            },
            footer: {
                alignment: 'right',
                bold: true,
                margin: [20,20,20,20], 
            },
        }
    }

    const document = {
		pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 80, 20, 60],
        styles: getStyles(),
        header: getHeader(),
        content: getContent(currentRows),
        footer: [{text: `Printed On: ${moment().format('l')}`, style: 'footer'}],
        images: {
            logo: logo,
        }
    }

    return document
}

export { pdfDocument }