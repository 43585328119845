import React, { useState } from "react";
import { Router } from "react-router-dom";
import { Container, Jumbotron } from "reactstrap";

import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";

import Activity from "./views/Activity";
import Customer from "./views/Customer";
import Setup from "./views/Setup";

import { useAuth0 } from "@auth0/auth0-react";
import history from "./utils/history";

// styles
import "./App.scss";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();

const App = () => {
  const { isLoading, error } = useAuth0();
  const [page, setPage] = useState();
  const [activeTab, setActiveTab] = useState();
  const { isAuthenticated } = useAuth0();
  const [token, setToken] = useState(null);
  const [port, setPort] = useState(null);

  const {
    getAccessTokenSilently,
  } = useAuth0();

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently();
      setToken(token)
    } catch (error) {
    }
  }

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  callApi();

  let pages = {
    activities: (<Activity token={token} port={port} />),
    customers: (<Customer token={token} port={port} />),
    setup: (<Setup token={token} port={port}/>),
  }

  const handlePageChange = (tab) => {
    setPage(pages[tab]);
    setActiveTab(tab);
  }

  return (
    <Router history={history}>
      <div id="app" className="d-flex flex-column h-100">
        <NavBar handlePageChange={handlePageChange} activeTab={activeTab} token={token} port={port} setPort={setPort}/>
        {!isAuthenticated && ( <Welcome /> )}
        {isAuthenticated && ( 
          <div className="page-container">
            {page}
          </div> 
        )}
        <Footer />
      </div>
    </Router>
  );
};

const Welcome = () => (
  <div className="jumbo-tall">
    <Jumbotron fluid className="jumbo-tall">
      <Container fluid>
        <h1 className="text-center display-3">Welcome</h1>
        <p className="text-center lead">Please sign in to begin the enhancing your business efficiency.</p>
      </Container>
    </Jumbotron>
  </div>
)

export default App;
