import React, { useState } from 'react';
import { Form, Row } from "reactstrap";
import { FormText, FormCheck, FormArea } from '../../components/FormComponents.js';

// Note: There is a known issue where shift tab does not work in a nested modal. https://github.com/reactstrap/reactstrap/issues/1497.

const CustomersForm = (props) => {
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  const Area = (col, field, desc, height) => {
    return (
      <FormArea colSize={col} field={field} description={desc}
      formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors} 
      height={height} />
    )
  }

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {

    if (!updatedField.firstName) errors.firstName = `First Name is required`
    else errors.firstName = null;

    if (!isStateValid(updatedField.state) && updatedField.state) errors.state = `Use valid two letter State`
    else errors.state = null;

    if (!isEmailValid(updatedField.emailAddress) && updatedField.emailAddress) errors.emailAddress = `Email is not valid`
    else errors.emailAddress = null;

    if (!isPhoneValid(updatedField.phoneNumber) && updatedField.phoneNumber) errors.phoneNumber = `Use XXX-XXX-XXXX format`
    else errors.phoneNumber = null;

    if (!isZipValid(updatedField.zip) && updatedField.zip) errors.zip = `Use 5-digit zip`
    else errors.zip = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  const isEmailValid = (emailAddress) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(emailAddress)) {
      return false 
    } else return true
  }

  const isPhoneValid = (phoneNumber) => {
    var pattern = new RegExp(/^\d{3}-\d{3}-\d{4}$/i);
    if (!pattern.test(phoneNumber)) {
      return false
    } else return true
  }

  const isZipValid = (zip) => {
    var pattern = new RegExp(/^\d{5}$/i);
    if (!pattern.test(zip)) {
      return false
    } else return true
  }

  const isStateValid = (state) => {
    var pattern = new RegExp(/^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/i);
    if (!pattern.test(state)) {
      return false
    } else return true
  }
  
  return (

    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("3", "firstName", "First Name")}
        {Text("3", "lastName", "Last Name")}
        {Text("2", "title", "Title")}
        {Text("2", "phoneNumber", "Phone Number")}
        {Text("2", "emailAddress", "Email Address")}
      </Row>
      <Row form>
        {Text("3", "address", "Address")}
        {Text("3", "city", "City")}
        {Text("2", "state", "State")}
        {Text("2", "zip", "Zip")}
        {Check("2", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
      <Row form>
        {Area("12", "customerContactNotes", "Customer Contact Notes", "5")}
      </Row>
    </Form>

  );
};

export default CustomersForm;