import React from "react";
import { Customers } from "../modules/customers/customers.js";

function Customer({ token, port }) {
  // Customers does not need a sidebar, so just return that react component to keep design consistency.

  return (
    <Customers token={token} port={port} />
  )
}

export default Customer;
