import React, { useState } from 'react';
import { dataPost } from '../utils/api.js';
import _ from 'lodash';
import ActionBar from '../components/ActionsBar.js';
import { ActivityTable } from '../modules/setup/activity.js';
import { CustomerTypesTable } from '../modules/setup/customer.js';
import { LeadTable } from '../modules/setup/leads.js';
import { UserTable } from '../modules/setup/users.js';
import { CustomersTable } from '../modules/customers/customers.js';
import { CustomerContactsTable } from '../modules/customers/customer-contacts.js';
import { ActivitiesTable } from '../modules/activities/activities.js';
import { OutboxTable } from '../modules/setup/outbox.js';
import { TemplatesTable } from '../modules/setup/templates.js';
import DataEntryModal from '../components/Modal.js';
import ActivitiesFilter from '../modules/activities/activities-filter.js';
import moment from 'moment';

const Grid = (params) => {
    // console.log('params', params);
    // Control the Data
    const [rows, setRows] = useState([]); // Full list of data that is filtered
    const [fullRows, setFullRows] = useState([]); // Full list of data cannot be filtered
    const [formRow, setFormRow] = useState({}); // This is the info that appears on a form for a single data line
    console.log('params', formRow);
    const [rowsEmpty, setRowsEmpty] = useState('loading'); // Puts up message when grid is blank

    const [sortDir, setSortDir] = useState(false); // Controls the sorting
    const [filter, setFilter] = useState({ search: '', active: 'active', criteria: [] }); // Controls the filters
    const [modal, setModal] = useState(false); // Controls the data form
    const [buttonDisabled, setButtonDisabled] = useState(() => false); // Disables the save button

    const [customer, setCustomer] = useState('-1');
    const [user, setUser] = useState('-1');
    const [activityType, setActivityType] = useState('-1');
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    // Opens form with correct info
    const toggle = (row, action) => {
        setModal(!modal);
        if (action === 'open') {
            setFormRow(params.editFormObject(row));
            setButtonDisabled(false);
        }
        if (action === 'save') saveRow();
        if (action === 'new') {
            setFormRow(params.newFormObject());
            setButtonDisabled(true);
        }
    };

    // Gets information for initial grid
    if (rows.length === 0)
        dataPost(params.selectAPI, params.api, {}, function (result) {
            if (result.length === 0) {
                setRowsEmpty('empty');
                return;
            }
            setRows(result);
            setFullRows(result);
            handleSetFilter(filter.search, filter.active, filter.criteria, _.orderBy(result, params.primarySort));
        });

    // Saves information to both databases for leads
    const saveRow = () => {
        console.log('data', params.updateAPI);
        dataPost(params.updateAPI, params.api, formRow, function (result) {
            if (result.insertId) {
                formRow.id = result.insertId;
                fullRows.push(formRow);
            }

            const newRows = _.map(fullRows, function (r) {
                if (r.id === formRow.id) return formRow;
                else return r;
            });

            // TO DO: This deals with the activities where the active state is recorded from a follow up date being needed.
            // Need to change MySQL to have the closed status just be driven off an active flag. Can get rid of the follow up flag. It's either closed or open.
            if (params.module === 'activities-activities')
                formRow.active = formRow.followUpRequired === -1 && formRow.followUpComplete !== -1 ? -1 : 0;

            if (params.saveFunction) params.saveFunction(result, formRow);

            setRows(newRows);
            setFullRows(newRows);
            handleSetFilter(filter.search, filter.active, filter.criteria, newRows);
        });
    };

    // Controls Sort
    const sortBy = (value, alpha) => {
        let newRows = alpha
            ? _.orderBy(rows, (r) => handleFilterNulls(r[value]).toLowerCase(), [sortDir ? 'asc' : 'desc'])
            : _.orderBy(rows, [value], [sortDir ? 'asc' : 'desc']);
        setRows(newRows);
        setSortDir(!sortDir);
    };

    // Controls Filter
    const handleSetFilter = (search, active, criteria, rows) => {
        const newRowList = rows ? rows : fullRows;
        const newSearch = search !== null ? search : filter.search;
        const newActive = active !== null ? active : filter.active;
        const newCriteria = criteria ? criteria : filter.criteria;

        const filteredList = _.filter(newRowList, (r) => {
            const rowInFilter = _.find(params.filterableFields, (f) =>
                handleFilterNulls(r[f]).toLowerCase().includes(newSearch.toLowerCase())
            );

            const rowInCriteria = newCriteria && newCriteria.length > 0 ? checkCriteria() : true;

            function checkCriteria() {
                const truthMap = _.map(newCriteria, (c) => {
                    let truth = true;

                    if (c.field === 'startDate' && (moment(r.followUpDate) < moment(c.value) || !r.followUpDate))
                        truth = false;
                    if (c.field === 'endDate' && (moment(r.followUpDate) > moment(c.value) || !r.followUpDate))
                        truth = false;
                    if (r[c.field] && parseInt(r[c.field]) !== c.value) truth = false;
                    if (c.field !== 'endDate' && c.field !== 'startDate' && !r[c.field] && c.value) truth = false;

                    return truth;
                });
                const trueRowsInCriteria = _.filter(truthMap, (r) => r);
                const allRowsInCriteriaTrue = newCriteria.length === trueRowsInCriteria.length;
                return allRowsInCriteriaTrue;
            }

            return rowInFilter && rowInCriteria && checkActiveStatus(newActive, r.active); // TO DO, Will Need to refactor to include multiple fields.
        });

        let sortedResults = _.orderBy(filteredList, (l) =>
            l[params.primarySort] ? l[params.primarySort].toLowerCase() : ''
        );
        updateFilters(sortedResults);
        setFilter({ search: newSearch, active: newActive, criteria: newCriteria });
    };

    const handleFilterNulls = (value) => {
        return value === null ? '' : value;
    };

    const updateFilters = (filteredList) => {
        if (filteredList.length === 0) {
            setRowsEmpty('empty');
        } else {
            setRows(filteredList);
            setRowsEmpty('loaded');
        }
    };

    // Controls Active / Inactive / All Status
    const checkActiveStatus = (status, value) => {
        if (status === 'active' && value === -1) return true;
        if (status === 'inactive' && value === 0) return true;
        if (status === 'all') return true;
        return false;
    };

    return (
        <div className='main-page'>
            {params.module === 'activities-activities' && (
                <ActivitiesFilter
                    customer={customer}
                    setCustomer={setCustomer}
                    user={user}
                    setUser={setUser}
                    activityType={activityType}
                    setActivityType={setActivityType}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    filter={filter}
                    handleSetFilter={handleSetFilter}
                    props={params}
                    rows={rows}
                />
            )}
            <ActionBar
                filter={filter}
                handleSetFilter={handleSetFilter}
                toggle={toggle}
                rowsEmpty={rowsEmpty}
                rowCount={rows.length}
            ></ActionBar>
            {rowsEmpty === 'loaded' && params.module === 'setup-leads' && (
                <LeadTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'setup-users' && (
                <UserTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'setup-customer-types' && (
                <CustomerTypesTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'setup-activity' && (
                <ActivityTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'setup-outbox' && (
                <OutboxTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'setup-template' && (
                <TemplatesTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'customers-customers' && (
                <CustomersTable sortBy={sortBy} rows={rows} toggle={toggle} api={params.api} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'customers-customer-contacts' && (
                <CustomerContactsTable sortBy={sortBy} rows={rows} toggle={toggle} />
            )}
            {rowsEmpty === 'loaded' && params.module === 'activities-activities' && (
                <ActivitiesTable sortBy={sortBy} rows={rows} toggle={toggle} api={params.api} />
            )}
            <DataEntryModal
                modal={modal}
                toggle={toggle}
                formRow={formRow}
                setFormRow={setFormRow}
                buttonDisabled={buttonDisabled}
                setButtonDisabled={setButtonDisabled}
                module={params.module}
                title={params.title}
                modalSize={params.modalSize}
                api={params.api}
            />
        </div>
    );
};

export default Grid;
