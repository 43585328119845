import React from 'react';
import _ from 'lodash';
import { dataPost } from '../../utils/api.js';
import { Table, Nav, NavLink } from 'reactstrap';
import Grid from '../../components/Grid.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdfDocument } from './customers-pdf.js';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import image from '../../assets/customer-logos/3003.js';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const Customers = (api) => {
    const selectAPI = 'customers-s';
    const updateAPI = 'customers-u';
    const primarySort = 'companyName';
    const filterableFields = [
        'companyName',
        'phoneNumber',
        'firstName',
        'lastName',
        'emailAddress',
        'address',
        'city',
        'state',
        'zip',
        'customerTypeDescription',
        'leadSourceDescription',
    ];
    const module = 'customers-customers';
    const title = 'Edit Customers';
    const modalSize = 'extra-wide-modal';

    const editFormObject = (row) => {
        return {
            id: row.id,
            companyName: row.companyName,
            phoneNumber: row.phoneNumber,
            firstName: row.firstName,
            lastName: row.lastName,
            title: row.title,
            address: row.address,
            city: row.city,
            state: row.state,
            zip: row.zip,
            emailAddress: row.emailAddress,
            customerNotes: row.customerNotes,
            leadSourceId: row.leadSourceId,
            leadSourceDescription: row.leadSourceDescription,
            customerTypeId: row.customerTypeId,
            customerTypeDescription: row.customerTypeDescription,
            safety: row.safety,
            cpr: row.cpr,
            sht: row.sht,
            as: row.as,
            active: row.active,
            new: false,
        };
    };

    const newFormObject = () => {
        return {
            id: null,
            companyName: '',
            phoneNumber: '',
            firstName: '',
            lastName: '',
            title: '',
            address: '',
            city: '',
            state: '',
            zip: '',
            emailAddress: '',
            customerNotes: '',
            leadSourceId: null,
            leadSourceDescription: '',
            customerTypeId: null,
            customerTypeDescription: '',
            safety: 0,
            cpr: 0,
            sht: 0,
            as: 0,
            active: -1,
            new: true,
        };
    };

    return (
        <Grid
            api={api}
            editFormObject={editFormObject}
            newFormObject={newFormObject}
            selectAPI={selectAPI}
            updateAPI={updateAPI}
            primarySort={primarySort}
            filterableFields={filterableFields}
            module={module}
            title={title}
            modalSize={modalSize}
        />
    );
};

const CustomersTable = ({ sortBy, rows, toggle, api }) => {
    const downloadPDF = (currentRow) => {
        dataPost('activity-s', api, {}, function (result) {
            const data = _.map(result, (r) => {
                if (r.customerId === currentRow.id)
                    return {
                        dateOfActivity: r.dateOfActivity,
                        activityTypeDescription: r.activityTypeDescription,
                        followUpRequired: r.followUpRequired,
                        followUpDate: r.followUpDate,
                        followUpComplete: r.followUpComplete,
                        completedDate: r.completedDate,
                        customerHistoryNote: r.customerHistoryNote,
                    };
            }).filter((result) => typeof result !== 'undefined');

            const orderedData = _.orderBy(data, ['dateOfActivity', 'followUpDate'], ['desc', 'desc']);
            const customerImage = api.port === 3003 ? image() : ''; // HACK FOR NOW WITH ONLY ONE LOGO.
            const pdf = pdfDocument(orderedData, currentRow.companyName, customerImage);
            pdfMake.createPdf(pdf).open();
        });
    };

    return (
        <div className='tableFixHead'>
            <Table className='table table-striped' hover>
                <thead>
                    <tr>
                        <th onClick={() => sortBy('companyName', true)}>
                            Company Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('firstName', true)}>
                            First Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('lastName', true)}>
                            Last Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('phoneNumber', true)} style={{ whiteSpace: 'nowrap' }}>
                            Phone Number <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('emailAddress', true)}>
                            Email Address <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('address', true)}>
                            Address <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('city', true)}>
                            City <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('state', true)}>
                            State <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('zip', true)}>
                            Zip <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('customerTypeDescription', true)}>
                            Customer Type <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('leadSourceDescription', true)}>
                            Lead Source <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('safety', false)} className='text-center'>
                            Safety
                        </th>
                        <th onClick={() => sortBy('cpr', false)} className='text-center'>
                            CPR
                        </th>
                        <th onClick={() => sortBy('sht', false)} className='text-center'>
                            SHT
                        </th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => {
                        return (
                            <tr key={i}>
                                <td onClick={() => toggle(row, 'open')}>{row.companyName}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.firstName}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.lastName}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.phoneNumber}</td>
                                <td>
                                    <a href={`mailto:${row.emailAddress}`}>{row.emailAddress}</a>
                                </td>
                                <td onClick={() => toggle(row, 'open')}>{row.address}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.city}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.state}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.zip}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.customerTypeDescription}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.leadSourceDescription}</td>
                                <td onClick={() => toggle(row, 'open')} className='text-center'>
                                    {row.safety === -1 ? 'X' : ''}
                                </td>
                                <td onClick={() => toggle(row, 'open')} className='text-center'>
                                    {row.cpr === -1 ? 'X' : ''}
                                </td>
                                <td onClick={() => toggle(row, 'open')} className='text-center'>
                                    {row.sht === -1 ? 'X' : ''}
                                </td>
                                <td style={{ padding: '0.2rem' }}>
                                    <Nav>
                                        <NavLink href='#' onClick={() => downloadPDF(row)}>
                                            <FontAwesomeIcon icon='print' />
                                        </NavLink>
                                    </Nav>
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export { Customers, CustomersTable };
