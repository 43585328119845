import React, { useState } from "react";
import { NavLink as RouterNavLink } from "react-router-dom";
import { Container, Navbar, Nav, NavItem, NavLink} from "reactstrap";
import CustomersForm from "./customers-form.js";
import { CustomerContacts } from "./customer-contacts.js";

const CustomersNavBar = ( {formRow, setFormRow, setButtonDisabled, api} ) => {
    const [activeTab, setActiveTab] = useState('info');

    const handlePageChange = (tab) => setActiveTab(tab);
    const isTabActive = (tab) => activeTab === tab;

    return (
        <div>
            {formRow.id && (<Navbar className="no-padding navbar" color="light" light expand="md">
                <Container>
                    <Nav className="mr-auto">
                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/" onClick={() => handlePageChange('info')} 
                                activeClassName={(isTabActive('info')) ? "router-link-exact-active" : ""} >
                                Info
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink tag={RouterNavLink} to="/" onClick={() => handlePageChange('additional')} 
                                activeClassName={(isTabActive('additional')) ? "router-link-exact-active" : ""} >
                                Additional Contacts
                            </NavLink>
                        </NavItem>
                    </Nav>
                </Container>
            </Navbar>)}
            <div className={formRow.id ? "page-container pc-modal" : "page-container"}>
                {activeTab === 'info' && (<CustomersForm formRow={formRow} setFormRow={setFormRow} setButtonDisabled={setButtonDisabled} api={api} />)}
                {activeTab === 'additional' && (<CustomerContacts  token={api.token} port={api.port} customerId={formRow.id} />)}
            </div>    
        </div>
    );
};

export default CustomersNavBar;
