import React, { useState } from 'react';
import { dataPost } from '../../utils/api.js';
import moment from 'moment';
import _ from 'lodash';

const CustomerList = ({ option, props, customers, setCustomers }) => {
    const [customersLoaded, setCustomersLoaded] = useState(false);

    if (!customersLoaded) {
        dataPost('customers-s', props.api, {}, function (result) {
            const customersOrdered = _.chain(result)
                .filter((r) => r.active === -1 || props.formRow.customerId === r.id)
                .orderBy('companyName')
                .value();

            customersOrdered.unshift({ id: 0, companyName: '' });
            setCustomers(customersOrdered);
        });
        setCustomersLoaded(true);
    }

    let customersList =
        customers.length > 0 &&
        customers.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.companyName}
                </option>
            );
        }, this);

    return <>{option('4', 'customer', 'Company Name', customersList)}</>;
};

const UserList = ({ option, props, users, setUsers }) => {
    const [usersLoaded, setUsersLoaded] = useState(false);

    if (!usersLoaded) {
        dataPost('users-s', props.api, {}, function (result) {
            const usersOrdered = _.chain(result)
                .filter((r) => r.active === -1 || props.formRow.userId === r.id)
                .orderBy('userName')
                .value();

            usersOrdered.unshift({ id: 0, userName: '' });
            setUsers(usersOrdered);
        });
        setUsersLoaded(true);
    }

    let usersList =
        users.length > 0 &&
        users.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.userName}
                </option>
            );
        }, this);

    return <>{option('4', 'user', 'User', usersList)}</>;
};

const ActivityTypeList = ({ option, props, activityTypes, setActivityTypes }) => {
    const [activityTypesLoaded, setActivityTypesLoaded] = useState(false);

    if (!activityTypesLoaded) {
        dataPost('activity-types-s', props.api, {}, function (result) {
            const activityTypesOrdered = _.chain(result)
                .filter((r) => r.active === -1 || props.formRow.activityTypeId === r.id)
                .orderBy('activityTypeDescription')
                .value();

            activityTypesOrdered.unshift({ id: 0, activityTypeDescription: '' });
            setActivityTypes(activityTypesOrdered);
        });
        setActivityTypesLoaded(true);
    }

    let activityTypesList =
        activityTypes.length > 0 &&
        activityTypes.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.activityTypeDescription}
                </option>
            );
        }, this);

    let data = {
        activityTypes: activityTypes,
    };

    return <>{option('4', 'activityType', 'Activity Types', activityTypesList, data)}</>;
};

const TemplateList = ({ option, props, templates, setTemplates }) => {
    const [templatesLoaded, setTemplatesLoaded] = useState(false);

    if (!templatesLoaded) {
        dataPost('template-s', props.api, {}, function (result) {
            const templatesOrdered = _.chain(result)
                .filter((r) => r.active === -1 || props.formRow.templateId === r.id)
                .orderBy('templateName')
                .value();

            templatesOrdered.unshift({ id: 0, templateName: '' });
            setTemplates(templatesOrdered);
        });
        setTemplatesLoaded(true);
    }

    let templatesList =
        templates.length > 0 &&
        templates.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.templateName}
                </option>
            );
        }, this);

    let data = {
        templates: templates,
    };

    return <>{option('12', 'template', 'Template', templatesList, data)}</>;
};

const OutboxList = ({ option, props, outbox, setOutbox }) => {
    const [outboxLoaded, setOutboxLoaded] = useState(false);

    if (!outboxLoaded) {
        dataPost('outbox-s', props.api, {}, function (result) {
            const outboxOrdered = _.chain(result)
                .filter((r) => r.active === -1 || props.formRow.outboxId === r.id)
                .orderBy('email')
                .value();

            outboxOrdered.unshift({ id: 0, email: '' });
            setOutbox(outboxOrdered);
        });
        setOutboxLoaded(true);
    }

    let outboxList =
        outbox.length > 0 &&
        outbox.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.email}
                </option>
            );
        }, this);

    return <>{option('12', 'outbox', 'Outbox', outboxList)}</>;
};

const sendEmail = (row) => {
    if (!row.outboxId && !row.sendText) return false; // No outgoing email
    if (!row.template) return false; // No message
    if (!row.followUpDate) return false; // No follow up date

    let now = moment().format('YYYY-MM-DD');
    let followUpDate = row.followUpDate.substring(0, 10);
    let lastEmailSentDate = row.lastEmailSentDate ? row.lastEmailSentDate.substring(0, 10) : null;

    if (now > followUpDate) return false; // Follow Up Date is in the past
    if (row.lastEmailSentDate && lastEmailSentDate >= followUpDate) return false; // last sent date is after follow up date

    return true;
};

// Add Outbox and Template Here!!!

export { CustomerList, UserList, ActivityTypeList, TemplateList, OutboxList, sendEmail };
