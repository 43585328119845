import React from "react";
import { Container, Row, Col, Button, ButtonGroup, FormGroup, Input } from "reactstrap";
import Loading from "../components/Loading"

const ActionBar = ({ filter, handleSetFilter, toggle, rowsEmpty, rowCount }) => {
    
    return (
        <Container className="actions-bar">
            <Row className="action-row">
                <Col>
                    <Row>
                        <Col sm="3">
                            <FormGroup row>
                                <Input type="input" name="search" id="searchField" placeholder="Search" value={filter.search} onChange={(e) => handleSetFilter(e.target.value, null)}/>
                            </FormGroup>
                        </Col>
                        <Col sm={{ size: 2, offset: 1 }} >
                            <FormGroup row>
                                <Col style={{border: "unset", fontWeight: "bold", padding: "0.5rem"}}>Record Count: {(rowsEmpty === 'empty') ? 0 : rowCount}</Col>
                            </FormGroup>
                        </Col>
                        <Col sm={{ size: 4, offset: 1 }}>
                            <ButtonGroup>
                                <Button outline color="primary" onClick={() => handleSetFilter(null, 'active')} active={filter.active === 'active'}>Active</Button>
                                <Button outline color="primary" onClick={() => handleSetFilter(null, 'inactive')} active={filter.active === 'inactive'}>Inactive</Button>
                                <Button outline color="primary" onClick={() => handleSetFilter(null, 'all')} active={filter.active === 'all'}>All</Button>
                            </ButtonGroup>
                        </Col>
                        <Col sm="1">
                            <Button color="primary" onClick={() => toggle(null, 'new')}>New</Button>{' '}
                        </Col>
                    </Row>
                </Col>
            </Row>
            {rowsEmpty === 'empty' && (
                <Row className="action-row">
                    <div>No Records Found</div>
                </Row>)
            }
            {rowsEmpty === 'loading' && (
                <Row>
                    <Loading />
                </Row>
                )
            }
        </Container>

    )
}

export default ActionBar;