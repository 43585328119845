import React, { useState } from 'react';
import { Form, Row } from "reactstrap";
import { FormText, FormCheck, FormArea } from '../../components/FormComponents.js';

const TemplatesForm = (props) => {
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  const Area = (col, field, desc, height) => {
    return (
      <FormArea colSize={col} field={field} description={desc}
      formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors} 
      height={height} />
    )
  }

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {

    if (!updatedField.templateName && updatedField.active === -1) errors.templateName = `Template Name is required`
    else errors.templateName = null;

    if (!updatedField.template && updatedField.active === -1) errors.template = `Template Name is required`
    else errors.template = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  return (
    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("6", "templateName", "Template Name")}
      </Row>      
      <Row form>
        {Area("12", "template", "Template", "20")}
      </Row>
      <Row form>
        {Check("6", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
    </Form>
  );
};

export default TemplatesForm;