import _ from 'lodash';
import moment from 'moment';

const pdfDocument = (currentRows, userName, logo) => {
    const getHeader = () => {
        const widths = logo ? [80, '*'] : ['*'];
        const body = logo
            ? [
                  { image: 'logo', width: 80 },
                  { text: `Follow Up Report for ${userName}`, style: 'header' },
              ]
            : [{ text: `Follow Up Report for ${userName}`, style: 'header' }];
        return {
            table: {
                widths: widths,
                body: [body],
            },
            layout: 'noBorders',
        };
    };

    const getContent = (rows) => {
        const header = [
            { text: 'Company Name', style: { fillColor: '#999999' } },
            { text: 'Activity', style: { fillColor: '#999999' } },
            { text: 'Notes', style: { fillColor: '#999999' } },
            { text: 'Email', style: { fillColor: '#999999' } },
            { text: 'Phone', style: { fillColor: '#999999' } },
            { text: 'Due Date', style: { fillColor: '#999999' } },
        ];

        let body = [];

        body.push(header);

        _.forEach(rows, (r) => {
            let row = [];

            row.push(r.companyName);
            row.push(r.activityTypeDescription);
            row.push(r.customerHistoryNote);
            row.push(r.emailAddress);
            row.push(r.phoneNumber);
            row.push(r.followUpDate ? moment.utc(r.followUpDate).format('l') : '');

            body.push(row);
        });

        return [
            {
                table: {
                    widths: [75, 75, 275, 100, 75, 75],
                    body: body,
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? '#E6E6E6' : null;
                    },
                },
                margin: [60, 0, 0, 0, 0, 0],
            },
        ];
    };

    const getBody = () => {
        let monthGrouping = _.chain(currentRows)
            .orderBy('followUpDate')
            .map((cr) => {
                return moment(cr.followUpDate).format('MMMM, YYYY');
            })
            .uniq()
            .value();
        let weekGrouping = _.chain(currentRows)
            .orderBy('followUpDate')
            .map((cr) => {
                return moment(cr.followUpDate).startOf('week').format('l');
            })
            .uniq()
            .value();

        let tableGrouped = [];

        _.forEach(monthGrouping, (m) => {
            if (m === 'Invalid date') return;
            tableGrouped.push({ text: m });

            const monthsWeeks = _.filter(weekGrouping, (g) => moment(g).format('MMMM, YYYY') === m);

            _.forEach(monthsWeeks, (w) => {
                tableGrouped.push({ text: `Week of ${w}`, style: 'week' });

                const weeksWork = _.filter(
                    currentRows,
                    (r) =>
                        moment(r.followUpDate).startOf('week').format('l') === w &&
                        moment(r.followUpDate).format('MMMM, YYYY') === m
                );

                tableGrouped.push(getContent(weeksWork));
            });
        });
        return tableGrouped;
    };

    const getStyles = () => {
        return {
            header: {
                fontSize: 20,
                alignment: 'center',
                bold: true,
                margin: [20, 20, 20, 20],
            },
            footer: {
                alignment: 'right',
                bold: true,
                margin: [20, 20, 20, 20],
            },
            week: {
                margin: [20, 0, 0, 0],
            },
        };
    };

    const document = {
        pageSize: 'A4',
        pageOrientation: 'landscape',
        pageMargins: [20, 80, 20, 60],
        styles: getStyles(),
        header: getHeader(),
        content: getBody(),
        footer: [{ text: `Printed On: ${moment().format('l')}`, style: 'footer' }],
        images: {
            logo: logo,
        },
    };

    return document;
};

export { pdfDocument };
