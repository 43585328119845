import React from "react";
import { Activities } from "../modules/activities/activities.js";

function Activity({ token, port }) {
  // Activity does not need a sidebar, so just return that react component to keep design consistency.

  return (
    <Activities token={token} port={port} />
  )
}

export default Activity;