import React, { useState } from 'react';
import { Form, Row } from "reactstrap";
import { FormText, FormCheck, FormOption } from '../../components/FormComponents.js';
import { dataPost } from "../../utils/api.js";
import _ from "lodash";

const ActivityForm = (props) => {
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});
  const [templates, setTemplates] = useState([]);
  const [outbox, setOutbox] = useState([]);

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  const Option = (col, field, desc, list) => {
    return (
      <FormOption colSize={col} field={field} description={desc}
      formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
      list={list} />
    )
  }

  // Gets information for initial grid
  if (templates.length === 0) dataPost('template-s', props.api, {}, function(result) {
    if (result.length === 0) return
    else {
      const templatesOrdered = _.chain(result)
        .filter(r => r.active === -1 || props.formRow.templateId === r.id)
        .orderBy('templateDescription')
        .value();

        templatesOrdered.unshift({ id: 0, templateDescription: '' })
      setTemplates(templatesOrdered);
    }
  });
  
  if (outbox.length === 0) dataPost('outbox-s', props.api, {}, function(result) {
    if (result.length === 0) return
    else {
      const outboxOrdered = _.chain(result)
        .filter(r => r.active === -1 || props.formRow.outboxId === r.id)
        .orderBy('outboxDescription')
        .value();

        outboxOrdered.unshift({ id: 0, email: '' })
      setOutbox(outboxOrdered);
    }
  });

  let templatesList = templates.length > 0
    && templates.map((item, i) => {
    return (
      <option key={i} value={item.id}>{item.templateDescription}</option>
    )
  }, this);

  let outboxList = outbox.length > 0
    && outbox.map((item, i) => {
    return (
      <option key={i} value={item.id}>{item.email}</option>
    )
  }, this);

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {

    if (!updatedField.activityTypeDescription && updatedField.active === -1) errors.activityTypeDescription = `Activity Type Description is required`
    else errors.activityTypeDescription = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  return (
    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("4", "activityTypeDescription", "Activity Type Description")}
        {Option("4", "template", "Templates", templatesList)}
        {Option("4", "outbox", "Outbox Email", outboxList)}
      </Row>
      <Row form>
        {Check("6", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
    </Form>
  );

};

export default ActivityForm;