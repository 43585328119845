import React, { useState } from 'react';
import { Form, Row, FormGroup, Label, Col, Button } from "reactstrap";
import { FormText, FormCheck, FormPassword } from '../../components/FormComponents.js';
import { dataPost } from "../../utils/api.js";

const OutboxForm = (props) => {
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});
  const [emailSent, setEmailSent] = useState(false);
  const [emailText, setEmailText] = useState('Send Test Email');
  const [buttonColor, setButtonColor] = useState('primary');

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Password = (col, field, desc) => {
    return (
      <FormPassword colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  // Opens form with correct info
  const sendEmail = () => {
    setEmailSent(true);
    setEmailText(`Sending Email to ${props.formRow.email}...`);
    setButtonColor('secondary');

    
    dataPost('outbox-send', props.api, { password: props.formRow.password, email: props.formRow.email}, function(result) {
      // Set the message of the button.
      setEmailText(`${result.message}\nTry Again?`);

      // Change the color of the button.
      let buttonColor = (result.error) ? 'danger' : 'success';
      setButtonColor(buttonColor);

      // Make the button enabled.
      setEmailSent(false);
    });
  }

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {
    setEmailText(`Send Test Email to ${props.formRow.email}`);
    setButtonColor('primary');

    if (!updatedField.email) errors.email = `Email is required`
    else if (!isEmailValid(updatedField.email)) errors.email = `Email is not valid`
    else errors.email = null;

    if (updatedField.password !== updatedField.confirmPassword) errors.confirmPassword = `Does not match password`
    else errors.confirmPassword = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  const isEmailValid = (email) => {

    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      return false 
    } else return true
  }

  return (
    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("6", "email", "Email")}
        <Col md="6">
          <FormGroup>
            <Label>
              Currently this application only supports Microsoft email accounts such as Live, Office 365, and Hotmail.
              Email addresses with your domain may be supported if the service provider is using Microsoft as the email platform.
            </Label>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {Password("6", "password", "Password")}
        {Password("6", "confirmPassword", "Confirm Password")}
      </Row>
      <Row form>
        <Button style={{ "white-space": "normal", "word-wrap": "break-word"}} color={buttonColor} disabled={ emailSent || !props.formRow.password || props.buttonDisabled } onClick={() => sendEmail(props.formRow, 'save')}>{emailText}</Button>
      </Row>
      <Row form>
        {Check("6", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
      <br />
    </Form>
  );
};

export default OutboxForm;