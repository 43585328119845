import React from 'react';
import { Table } from 'reactstrap';
import { getPort } from '../../utils/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Grid from '../../components/Grid.js';

const Users = (api) => {
    const selectAPI = 'users-s';
    const updateAPI = 'users-u';
    const primarySort = 'userName';
    const filterableFields = ['userName', 'email'];
    const module = 'setup-users';
    const title = 'Edit User';
    const modalSize = 'wide-modal';

    const editFormObject = (row) => {
        return {
            id: row.id,
            userName: row.userName,
            email: row.email,
            password: '',
            confirmPassword: '',
            admin: row.admin,
            active: row.active,
            new: false,
        };
    };

    const newFormObject = () => {
        return {
            id: null,
            userName: '',
            email: '',
            password: '',
            confirmPassword: '',
            admin: 0,
            active: -1,
            new: true,
        };
    };

    const saveFunction = (result, formUser) => {
        const authUser = result.insertId
            ? {
                  isNew: true,
                  userName: formUser.userName,
                  email: formUser.email,
                  password: formUser.password,
                  userId: result.insertId,
              }
            : {
                  isNew: false,
                  userName: formUser.userName,
                  email: formUser.email,
                  password: formUser.password,
                  userId: formUser.id,
              };

        getPort('users', api, { user: authUser, port: api.port }, function () {
            // Do Nothing
        });
    };

    return (
        <Grid
            api={api}
            editFormObject={editFormObject}
            newFormObject={newFormObject}
            selectAPI={selectAPI}
            updateAPI={updateAPI}
            primarySort={primarySort}
            filterableFields={filterableFields}
            module={module}
            title={title}
            modalSize={modalSize}
            saveFunction={saveFunction} // Special to Users Grid for purpose of saving user to Auth0
        />
    );
};

const UserTable = ({ sortBy, toggle, rows }) => {
    return (
        <div className='tableFixHead'>
            <Table className='table table-striped' hover>
                <thead>
                    <tr>
                        <th onClick={() => sortBy('userName', true)}>
                            Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('email', true)}>
                            Email <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th className='text-center' onClick={() => sortBy('admin', false)}>
                            Admin
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => {
                        return (
                            <tr key={i}>
                                <td onClick={() => toggle(row, 'open')}>{row.userName}</td>
                                <td onClick={() => toggle(row, 'open')}>{row.email}</td>
                                <td className='text-center' onClick={() => toggle(row, 'open')}>
                                    {row.admin === -1 ? 'X' : ''}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export { Users, UserTable };
