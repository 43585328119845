import React from "react";
import { Table } from "reactstrap";
import Grid from "../../components/Grid.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomerTypes = ( api ) => {
  const selectAPI = 'customer-types-s';
  const updateAPI = 'customer-types-u';
  const primarySort = 'customerTypeDescription';
  const filterableFields = ['customerTypeDescription'];
  const module="setup-customer-types";
  const title="Edit Customer Type";
  const modalSize="wide-modal";

  const editFormObject = (row) => {
    return {
      id: row.id,
      customerTypeDescription: row.customerTypeDescription, 
      active: row.active, 
      new: false,
    }
  }

  const newFormObject = () => {
    return {
      id: null,
      customerTypeDescription: '', 
      active: -1, 
      new: true,
    }
  }

  return (
    <Grid 
      api={api} 
      editFormObject={editFormObject} 
      newFormObject={newFormObject} 
      selectAPI={selectAPI}
      updateAPI={updateAPI}
      primarySort={primarySort}
      filterableFields={filterableFields}
      module={module}
      title={title}
      modalSize={modalSize}
    />
  )
}

const CustomerTypesTable = ({ sortBy, rows, toggle }) => {
  return (
    <div className="tableFixHead">
      <Table className="table table-striped" hover>
        <thead>
          <tr>
            <th onClick={() => sortBy('customerTypeDescription', true)}>Customer Type <FontAwesomeIcon icon="search" className="mr-2" /></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {return (
            <tr key={i}>
              <td onClick={() => toggle(row, 'open')}>{row.customerTypeDescription}</td>
            </tr>
          )})}
        </tbody>
      </Table>
    </div>
  )
}

export { CustomerTypes, CustomerTypesTable }