import React from "react";

const Footer = () => (
  <footer className="footer text-center">
    <p>
      This software is a product of <a target="_blank" rel="noopener noreferrer" href="http://bytrek.com">Bytrek</a>
    </p>
  </footer>
);

export default Footer;
