import React from "react";
import { Table } from "reactstrap";
import Grid from "../../components/Grid.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Templates = ( api ) => {
    const selectAPI = 'template-s';
    const updateAPI = 'template-u';
    const primarySort = 'templateName';
    const filterableFields = ['templateName'];
    const module="setup-template";
    const title="Edit Template";
    const modalSize="wide-modal";
  
    const editFormObject = (row) => {
      return {
        id: row.id,
        templateName: row.templateName, 
        template: row.template, 
        active: row.active, 
        new: false,
      }
    }
  
    const newFormObject = () => {
        return {
            id: null,
            templateName: '', 
            template: '',
            active: -1, 
            new: true,
          }
    }
  
    return (
      <Grid 
        api={api} 
        editFormObject={editFormObject} 
        newFormObject={newFormObject} 
        selectAPI={selectAPI}
        updateAPI={updateAPI}
        primarySort={primarySort}
        filterableFields={filterableFields}
        module={module}
        title={title}
        modalSize={modalSize}
      />
    )
}

const TemplatesTable = ({ sortBy, rows, toggle }) => {
  return (
    <div className="tableFixHead">
      <Table className="table table-striped" hover>
        <thead>
          <tr>
            <th onClick={() => sortBy('templateName', true)}>Template <FontAwesomeIcon icon="search" className="mr-2" /></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {return (
            <tr key={i}>
              <td onClick={() => toggle(row, 'open')}>{row.templateName}</td>
            </tr>
          )})}
        </tbody>
      </Table>
    </div>
  )
}

export { Templates, TemplatesTable }