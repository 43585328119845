import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import FocusLock from 'react-focus-lock';
import ActivityForm from '../modules/setup/activity-form.js';
import UsersForm from '../modules/setup/users-form.js';
import OutboxForm from '../modules/setup/outbox-form.js';
import TemplatesForm from '../modules/setup/templates-form.js';
import LeadsForm from '../modules/setup/leads-form.js';
import CustomerTypeForm from '../modules/setup/customer-form.js';
import CustomersNavBar from '../modules/customers/customers-nav.js';
import CustomerContactForm from '../modules/customers/customer-contact-form.js';
import ActivitiesForm from '../modules/activities/activities-form.js';

// TO DO; Add Activities Grid
// There is really just one activities form. Needs sort by user and date selectors to be effective. Also needs a print button.
// Sort by user selection. This would be the same as "Users --> Activity Form". 
// Add a user "Unassigned" and "All". Not all activities need a user. Default the selected user to the signed in user.
// Choosing a date and hitting print would be same as follow up report.
// Activities Report in customers could have the selector bar. It would just need to default to records only for that customer. Also could have print button.
// Add print button to customer grid as well.
// Home page would be the printable follow up report for the signed in user. Do we want a selector there? Or would it be the grid with a print button. 
//   This effectively would be the same as going to the Actvities tab. Get rid of home page and make activities first page.
//   Then make scheduled the first selection. This would effectively work. Could get of the entire sidebar on Actvities. It is all controlled by date / user selectors.
//   Date and user would be an action bar below the current action bar!!! - Simple!!!
// Printable report needs to be sorted by week. Printed Report would show User and Date in Header (Those are the two selectors!)

const DataEntryModal = (props) => {
    return (
      <Modal isOpen={props.modal} toggle={props.toggle} className={props.modalSize}>
        <ModalHeader toggle={props.toggle}>{props.title}</ModalHeader>
          <ModalBody>
            {props.module === 'setup-users' && (<UsersForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled}/>)}
            {props.module === 'setup-outbox' && (<OutboxForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled} buttonDisabled={props.buttonDisabled} api={props.api}/>)}
            {props.module === 'setup-leads' && (<LeadsForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled}/>)}
            {props.module === 'setup-template' && (<TemplatesForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled}/>)}
            {props.module === 'setup-customer-types' && (<CustomerTypeForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled}/>)}
            {props.module === 'setup-activity' && (<ActivityForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled} api={props.api}/>)}
            {props.module === 'customers-customers' && (<CustomersNavBar formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled} api={props.api} />)}
            {props.module === 'customers-customer-contacts' && (<FocusLock><CustomerContactForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled} api={props.api} /></FocusLock>)}
            {props.module === 'activities-activities' && (<ActivitiesForm formRow={props.formRow} setFormRow={props.setFormRow} setButtonDisabled={props.setButtonDisabled} api={props.api}/>)}
          </ModalBody>
        <ModalFooter>
          <Button color="primary" disabled={ props.buttonDisabled } onClick={() => props.toggle(props.formRow, 'save')}>Save</Button>{' '}
          <Button color="secondary" onClick={() => props.toggle(null, 'close')}>Cancel</Button>
        </ModalFooter>
      </Modal>
    )
}

export default DataEntryModal;