import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container, Row, Col, FormGroup, Input, Button, Tooltip } from 'reactstrap';
import { dataPost } from '../../utils/api.js';
import image from '../../assets/customer-logos/3003.js';
import _ from 'lodash';
import { pdfDocument } from './activities-pdf.js';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ActivitiesFilter = ({
    customer,
    setCustomer,
    user,
    setUser,
    activityType,
    setActivityType,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    props,
    rows,
    filter,
    handleSetFilter,
}) => {
    const [customers, setCustomers] = useState([]);
    const [customersLoaded, setCustomersLoaded] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersLoaded, setUsersLoaded] = useState(false);
    const [activitiyTypes, setActivityTypes] = useState([]);
    const [activityTypesLoaded, setActivityTypesLoaded] = useState(false);

    if (!customersLoaded && props.api.port) {
        dataPost('customers-s', props.api, {}, function (result) {
            const customersOrdered = _.chain(result)
                .filter((r) => r.active === -1)
                .orderBy('companyName')
                .value();
            customersOrdered.unshift({ id: 0, companyName: 'None' });
            customersOrdered.unshift({ id: -1, companyName: 'All' });
            setCustomers(customersOrdered);
        });
        setCustomersLoaded(true);
    }

    let customersList =
        customers.length > 0 &&
        customers.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.companyName}
                </option>
            );
        }, this);

    if (!usersLoaded && props.api.port) {
        dataPost('users-s', props.api, {}, function (result) {
            const usersOrdered = _.chain(result)
                .filter((r) => r.active === -1)
                .orderBy('userName')
                .value();
            usersOrdered.unshift({ id: 0, userName: 'None' });
            usersOrdered.unshift({ id: -1, userName: 'All' });
            setUsers(usersOrdered);
        });
        setUsersLoaded(true);
    }

    let usersList =
        users.length > 0 &&
        users.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.userName}
                </option>
            );
        }, this);

    if (!activityTypesLoaded && props.api.port) {
        dataPost('activity-types-s', props.api, {}, function (result) {
            const activityTypesOrdered = _.chain(result)
                .filter((r) => r.active === -1)
                .orderBy('activityTypeDescription')
                .value();
            activityTypesOrdered.unshift({ id: 0, activityTypeDescription: 'None' });
            activityTypesOrdered.unshift({ id: -1, activityTypeDescription: 'All' });
            setActivityTypes(activityTypesOrdered);
        });
        setActivityTypesLoaded(true);
    }

    let activityTypesList =
        activitiyTypes.length > 0 &&
        activitiyTypes.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.activityTypeDescription}
                </option>
            );
        }, this);

    const handleCriteriaChange = (value, field, criteriaFunction) => {
        criteriaFunction(value);
        const hasField = _.filter(filter.criteria, (c) => c.field === field);
        if (value === '-1' || value === '') {
            // removes criteria for that field
            const allCustomers = _.filter(filter.criteria, (c) => c.field !== field);
            handleSetFilter(null, null, allCustomers);
        } else {
            let newValue = value === '0' ? null : parseInt(value);
            if (field === 'startDate' || field === 'endDate') newValue = value;
            if (hasField.length > 0)
                _.map(filter.criteria, (c) => {
                    if (c.field === field) c.value = newValue;
                });
            else filter.criteria.push({ field: field, value: newValue });
            handleSetFilter(null, null, filter.criteria);
        }
    };

    const downloadPDF = (currentRows) => {
        const userName = _.filter(users, (u) => u.id.toString() === user.toString())[0].userName;
        const customerImage = props.api.port === 3003 ? image() : ''; // HACK FOR NOW WITH ONLY ONE LOGO.
        console.log('customerImage', currentRows);
        const pdf = pdfDocument(currentRows, userName, customerImage);
        pdfMake.createPdf(pdf).open();
    };

    const [startTooltipOpen, setStartTooltipOpen] = useState(false);

    const toggleStart = () => setStartTooltipOpen(!startTooltipOpen);

    const [endTooltipOpen, setEndTooltipOpen] = useState(false);

    const toggleEnd = () => setEndTooltipOpen(!endTooltipOpen);

    return (
        <Container className='actions-bar'>
            <Row className='action-row'>
                <Col sm='1'>
                    <FormGroup row>
                        <Col className='text-right' style={{ border: 'unset', fontWeight: 'bold', padding: '0.5rem' }}>
                            Select User
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm='1'>
                    <FormGroup row>
                        <Input
                            type='select'
                            name='user'
                            id='user'
                            onChange={(e) => handleCriteriaChange(e.target.value, 'userId', setUser)}
                            value={user}
                        >
                            {usersList}
                        </Input>
                    </FormGroup>
                </Col>

                <Col sm='1'>
                    <FormGroup row>
                        <Col className='text-right' style={{ border: 'unset', fontWeight: 'bold', padding: '0.5rem' }}>
                            Select Activity Type
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm='1'>
                    <FormGroup row>
                        <Input
                            type='select'
                            name='activityType'
                            id='activityType'
                            onChange={(e) => handleCriteriaChange(e.target.value, 'activityTypeId', setActivityType)}
                            value={activityType}
                        >
                            {activityTypesList}
                        </Input>
                    </FormGroup>
                </Col>

                <Col sm='1'>
                    <FormGroup row>
                        <Col className='text-right' style={{ border: 'unset', fontWeight: 'bold', padding: '0.5rem' }}>
                            Select Company
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm='1'>
                    <FormGroup row>
                        <Input
                            type='select'
                            name='customer'
                            id='customer'
                            onChange={(e) => handleCriteriaChange(e.target.value, 'customerId', setCustomer)}
                            value={customer}
                        >
                            {customersList}
                        </Input>
                    </FormGroup>
                </Col>

                <Col sm='1'>
                    <FormGroup row>
                        <Col className='text-right' style={{ border: 'unset', fontWeight: 'bold', padding: '0.5rem' }}>
                            Start Date
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm='1'>
                    <FormGroup row>
                        <span style={{ textDecoration: 'underline', color: 'blue' }} href='#' id='startToolTip'>
                            <Input
                                type='date'
                                name='startDate'
                                onChange={(e) => handleCriteriaChange(e.target.value, 'startDate', setStartDate)}
                                onDoubleClick={(e) => handleCriteriaChange('', 'startDate', setStartDate)}
                                value={
                                    moment(startDate).isValid() && startDate.length === 24
                                        ? moment.utc(startDate).format('YYYY-MM-DD')
                                        : startDate
                                }
                            />
                        </span>
                        <Tooltip placement='bottom' isOpen={startTooltipOpen} target='startToolTip' toggle={toggleStart}>
                            Double Click to Clear
                        </Tooltip>
                    </FormGroup>
                </Col>

                <Col sm='1'>
                    <FormGroup row>
                        <Col className='text-right' style={{ border: 'unset', fontWeight: 'bold', padding: '0.5rem' }}>
                            End Date
                        </Col>
                    </FormGroup>
                </Col>
                <Col sm='1'>
                    <FormGroup row>
                        <span style={{ textDecoration: 'underline', color: 'blue' }} href='#' id='endToolTip'>
                            <Input
                                type='date'
                                name='endDate'
                                onChange={(e) => handleCriteriaChange(e.target.value, 'endDate', setEndDate)}
                                onDoubleClick={(e) => handleCriteriaChange('', 'endDate', setEndDate)}
                                value={
                                    moment(endDate).isValid() && endDate.length === 24
                                        ? moment.utc(endDate).format('YYYY-MM-DD')
                                        : endDate
                                }
                            />
                        </span>
                    </FormGroup>
                    <Tooltip placement='bottom' isOpen={endTooltipOpen} target='endToolTip' toggle={toggleEnd}>
                        Double Click to Clear
                    </Tooltip>
                </Col>

                <Col sm='1' className='text-right'>
                    <Button color='primary' onClick={() => downloadPDF(rows)}>
                        <FontAwesomeIcon icon='print' />
                    </Button>
                </Col>
            </Row>
        </Container>
    );
};

export default ActivitiesFilter;
