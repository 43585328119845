import React, { useState } from 'react';
import moment from 'moment';
import { Form, Row, Col, FormGroup, Label, Input } from 'reactstrap';
import { FormCheck, FormArea, FormDate, FormOption } from '../../components/FormComponents.js';
import _ from 'lodash';
import { CustomerList, UserList, ActivityTypeList, TemplateList, OutboxList, sendEmail } from './activities.options.js';

const ActivitiesForm = (props) => {
    const [followUpCheckbox, setFollowUpCheckbox] = useState(props.formRow.followUpRequired === -1 ? true : false);
    const [followUpTextMessage, setFollowUpTextMessage] = useState(props.formRow.sendText === -1 ? true : false);
    const [completedCheckbox, setCompletedCheckbox] = useState(props.formRow.followUpComplete === -1 ? true : false);
    const [activityTypes, setActivityTypes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [users, setUsers] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [outbox, setOutbox] = useState([]);
    const [errors] = useState({});

    const Date = (col, field, desc) => {
        return (
            <FormDate
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
            />
        );
    };

    const Check = (col, field, desc, setCheckbox, checkbox) => {
        return (
            <FormCheck
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                setCheckbox={setCheckbox}
                checkbox={checkbox}
            />
        );
    };

    const Option = (col, field, desc, list, data) => {
        return (
            <FormOption
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                list={list}
                data={data}
            />
        );
    };

    const Area = (col, field, desc, height) => {
        return (
            <FormArea
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                height={height}
            />
        );
    };

    // Error Checking and throw warnings
    const checkForErrors = (updatedField) => {
        const company = _.find(customers, (c) => {
            if (!updatedField.customerId)
                return {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    emailAdress: '',
                };
            return c.id.toString() === updatedField.customerId.toString();
        });

        updatedField.firstName = company.firstName;
        updatedField.lastName = company.lastName;
        updatedField.phoneNumber = company.phoneNumber;
        updatedField.emailAddress = company.emailAddress;

        if (
            (!updatedField.companyName || updatedField.companyName === '') &&
            (!updatedField.userName || updatedField.userName === '')
        ) {
            errors.customer = `Company Name Or User is required`;
            errors.user = `Company Name Or User is required`;
        } else {
            errors.customer = null;
            errors.user = null;
        }

        if (
            (!updatedField.activityTypeDescription || updatedField.activityTypeDescription === '') &&
            (!updatedField.customerHistoryNote || updatedField.customerHistoryNote === '')
        ) {
            errors.activityType = `Activity Type or Note is required`;
            errors.customerHistoryNote = `Activity Type or Note is required`;
        } else {
            errors.activityType = null;
            errors.customerHistoryNote = null;
        }

        if (!moment(updatedField.dateOfActivity).isValid()) errors.dateOfActivity = `Date of Activity is required`;
        else errors.dateOfActivity = null;

        let validFollowUp = moment(updatedField.followUpDate).isValid();
        // if (
        //     (updatedField.followUpRequired === -1 && !validFollowUp) ||
        //     (updatedField.followUpRequired !== -1 && validFollowUp) ||
        //     (updatedField.sendText === -1 && !validFollowUp) ||
        //     (updatedField.sendText !== -1 && validFollowUp)
        // )
        //     errors.followUpDate = `Resolve Follow Up and Date Mismatch`;
        // else errors.followUpDate = null;

        let validCompleted = moment(updatedField.completedDate).isValid();
        if (
            ((updatedField.followUpComplete === -1 && !validCompleted) ||
                (updatedField.followUpComplete !== -1 && validCompleted)) &&
            ((updatedField.sendText !== -1 && !validCompleted) || (updatedField.sendText === -1 && validCompleted))
        )
            errors.completedDate = `Resolve Follow Up and Date Mismatch`;
        else errors.completedDate = null;

        if (updatedField.followUpRequired === 0 && updatedField.followUpComplete === 0 && updatedField.sendText === 0) {
            errors.followUpRequired = `Either A Follow Up or Completed Must Be Selected`;
            errors.followUpComplete = `Either A Follow Up or Completed Must Be Selected`;
            errors.sendText = `Either A Follow Up or Completed Must Be Selected`;
        } else {
            errors.followUpRequired = null;
            errors.followUpComplete = null;
            errors.sendText = null;
        }

        const errorsExist = Object.values(errors).some((e) => Boolean(e));
        props.setButtonDisabled(errorsExist);
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Row form>
                <CustomerList option={Option} props={props} customers={customers} setCustomers={setCustomers} />
                <UserList option={Option} props={props} users={users} setUsers={setUsers} />
                <ActivityTypeList
                    option={Option}
                    props={props}
                    activityTypes={activityTypes}
                    setActivityTypes={setActivityTypes}
                />
            </Row>
            <Row form>{Area('12', 'customerHistoryNote', 'Activity Notes', '12')}</Row>
            <Row form>
                {Date('2', 'dateOfActivity', 'Activity Date')}
                <Col md='1'></Col>
                {Check('2', 'followUpRequired', 'Follow Up Email', setFollowUpCheckbox, followUpCheckbox)}
                {Check('2', 'sendText', 'Follow Up  Text', setFollowUpTextMessage, followUpTextMessage)}
                {Date('2', 'followUpDate', 'Follow Up Date')}
                <Col md='1'></Col>
                {Check('2', 'followUpComplete', 'Activity Completed?', setCompletedCheckbox, completedCheckbox)}
                {Date('2', 'completedDate', 'Completed Date')}
            </Row>
            <Row form>
                <Col md='4'>
                    <Row form>
                        <Col md='6'>
                            <FormGroup>
                                <Label for='sendEmail'>Follow Up Pending</Label>
                                <Input
                                    name='sendEmail'
                                    value={
                                        sendEmail(props.formRow)
                                            ? `Will Be Sent: ${moment.utc(props.formRow.followUpDate).format('MM/DD/YYYY')}`
                                            : ''
                                    }
                                    disabled={true}
                                    style={{ backgroundColor: sendEmail(props.formRow) ? 'lightgreen' : '' }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for='lastEmailSentDate'>Last Message Sent On</Label>
                                <Input
                                    name='lastEmailSentDate'
                                    value={
                                        props.formRow.lastEmailSentDate
                                            ? moment.utc(props.formRow.lastEmailSentDate).format('MM/DD/YYYY')
                                            : ''
                                    }
                                    disabled={true}
                                />
                            </FormGroup>
                        </Col>
                        <Col md='6'>
                            <TemplateList option={Option} props={props} templates={templates} setTemplates={setTemplates} />
                            <OutboxList option={Option} props={props} outbox={outbox} setOutbox={setOutbox} />
                        </Col>
                    </Row>
                </Col>
                <Col md='8'>
                    {Area('12', 'template', 'Message to be sent via Email and/or Text depending on selections above', '6')}
                </Col>
            </Row>
        </Form>
    );
};

export default ActivitiesForm;
