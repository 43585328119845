import React, { useState } from "react";
import Sidebar from "../components/Sidebar";
import { Activity } from "../modules/setup/activity.js";
import { CustomerTypes } from "../modules/setup/customer.js";
import { Leads } from "../modules/setup/leads.js";
import { Users } from "../modules/setup/users.js";
import { Outbox } from "../modules/setup/outbox.js";
import { Templates } from "../modules/setup/templates.js";

function Setup({ token, port }) {
  const tabs=[
    { name: "activity", description: "Activity", class: <Activity token={token} port={port} /> }, 
    { name: "customer", description: "Customer", class: <CustomerTypes token={token} port={port} />},
    { name: "lead", description: "Lead", class: <Leads token={token} port={port} />},
    { name: "user", description: "Users", class: <Users token={token} port={port} />},
    { name: "outbox", description: "Outbox", class: <Outbox token={token} port={port} />},
    { name: "template", description: "Templates", class: <Templates token={token} port={port} />},
  ];

  const [activeTab, setActiveTab] = useState(tabs[0]);
  const [page, setPage] = useState(tabs[0].class);

  const handlePageChange = (tab) => {
    setPage(tab.class);
    setActiveTab(tab);
  }

  return ( 
    <>
      <Sidebar className="sidebar" handlePageChange={handlePageChange} activeTab={activeTab} tabs={tabs} ></Sidebar>
      {page}
    </>
  )
};

export default Setup;