import React, { useState } from 'react';
import { Form, Row } from "reactstrap";
import { FormText, FormCheck } from '../../components/FormComponents.js';

const LeadsForm = (props) => {
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {

    if (!updatedField.leadSourceDescription && updatedField.active === -1) errors.leadSourceDescription = `Lead Source Description is required`
    else errors.leadSourceDescription = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  return (
    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("6", "leadSourceDescription", "Lead Source Description")}
      </Row>
      <Row form>
        {Check("6", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
    </Form>
  );
};

export default LeadsForm;