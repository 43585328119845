import React, { useState } from 'react';
import { Form, Row } from "reactstrap";
import { FormText, FormCheck, FormPassword } from '../../components/FormComponents.js';

const UsersForm = (props) => {
  const [adminCheckbox, setAdminCheckbox] = useState((props.formRow.admin === -1) ? true : false);
  const [activeCheckbox, setActiveCheckbox] = useState((props.formRow.active === -1) ? true : false);
  const [errors] = useState({});

  const Text = (col, field, desc) => {
    return (
      <FormText colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Password = (col, field, desc) => {
    return (
      <FormPassword colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors}  errors={errors} />
    )
  }

  const Check = (col, field, desc, setCheckbox, checkbox) => {
    return (
      <FormCheck colSize={col} field={field} description={desc}
        formRow={props.formRow} setFormRow={props.setFormRow} checkForErrors={checkForErrors} errors={errors}
        setCheckbox={setCheckbox} checkbox={checkbox} />
    )
  }

  // Error Checking and throw warnings
  const checkForErrors = (updatedField) => {
    if (!updatedField.email) errors.email = `Email is required`
    else if (!isEmailValid(updatedField.email)) errors.email = `Email is not valid`
    else errors.email = null;

    if (!updatedField.userName) errors.userName = `Name is required`
    else errors.userName = null;

    if ((!isPasswordValid(updatedField.password) && updatedField.password) ||
      (!updatedField.password && updatedField.new)) errors.password = `Password must by >= 8 characters with a number, lowercase letter, uppercase letter and a special character`
    else errors.password = null;

    if (updatedField.password !== updatedField.confirmPassword) errors.confirmPassword = `Does not match password`
    else errors.confirmPassword = null;

    const errorsExist = Object.values(errors).some(e => Boolean(e));
    props.setButtonDisabled(errorsExist);
  }

  const isEmailValid = (email) => {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
      return false 
    } else return true
  }

  const isPasswordValid = (password) => {
    var pattern = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
    if (!pattern.test(password)) {
      return false
    } else return true
  }

  return (
    <Form onSubmit={e => e.preventDefault()} autoComplete="off">
      <Row form>
        {Text("6", "userName", "Name")}
        {Text("6", "email", "Email")}
      </Row>
      <Row>
        {Password("6", "password", "Password")}
        {Password("6", "confirmPassword", "Confirm Password")}
      </Row>
      <Row form>
        {Check("6", "admin", "Is Admin?", setAdminCheckbox, adminCheckbox)}
        {Check("6", "active", "Is Active?", setActiveCheckbox, activeCheckbox)}
      </Row>
    </Form>
  );
};

export default UsersForm;