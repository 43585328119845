import React, { useState } from 'react';
import { Form, Row } from 'reactstrap';
import { FormText, FormCheck, FormOption, FormArea } from '../../components/FormComponents.js';
import { dataPost } from '../../utils/api.js';
import _ from 'lodash';

const CustomersForm = (props) => {
    const [activeCheckbox, setActiveCheckbox] = useState(props.formRow.active === -1 ? true : false);
    const [safetyCheckbox, setSafetyCheckbox] = useState(props.formRow.safety === -1 ? true : false);
    const [cprCheckbox, setCprCheckbox] = useState(props.formRow.cpr === -1 ? true : false);
    const [shtCheckbox, setShtCheckbox] = useState(props.formRow.sht === -1 ? true : false);
    const [asCheckbox, setAsCheckbox] = useState(props.formRow.as === -1 ? true : false);
    const [errors] = useState({});
    const [leadSources, setLeadSources] = useState([]);
    const [customerTypes, setCustomerTypes] = useState([]);

    const Text = (col, field, desc) => {
        return (
            <FormText
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
            />
        );
    };

    const Check = (col, field, desc, setCheckbox, checkbox) => {
        return (
            <FormCheck
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                setCheckbox={setCheckbox}
                checkbox={checkbox}
            />
        );
    };

    const Option = (col, field, desc, list) => {
        return (
            <FormOption
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                list={list}
            />
        );
    };

    const Area = (col, field, desc, height) => {
        return (
            <FormArea
                colSize={col}
                field={field}
                description={desc}
                formRow={props.formRow}
                setFormRow={props.setFormRow}
                checkForErrors={checkForErrors}
                errors={errors}
                height={height}
            />
        );
    };

    // Gets information for initial grid
    if (leadSources.length === 0)
        dataPost('leads-s', props.api, {}, function (result) {
            if (result.length === 0) return;
            else {
                const leadSourceOrdered = _.chain(result)
                    .filter((r) => r.active === -1 || props.formRow.leadSourceId === r.id)
                    .orderBy('leadSourceDescription')
                    .value();

                leadSourceOrdered.unshift({ id: 0, leadSourceDescription: '' });
                setLeadSources(leadSourceOrdered);
            }
        });

    if (customerTypes.length === 0)
        dataPost('customer-types-s', props.api, {}, function (result) {
            if (result.length === 0) return;
            else {
                const customerTypeOrdered = _.chain(result)
                    .filter((r) => r.active === -1 || props.formRow.customerTypeId === r.id)
                    .orderBy('customerTypeDescription')
                    .value();

                customerTypeOrdered.unshift({ id: 0, customerTypeDescription: '' });
                setCustomerTypes(customerTypeOrdered);
            }
        });

    let leadSourcesList =
        leadSources.length > 0 &&
        leadSources.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.leadSourceDescription}
                </option>
            );
        }, this);

    let customerTypeList =
        customerTypes.length > 0 &&
        customerTypes.map((item, i) => {
            return (
                <option key={i} value={item.id}>
                    {item.customerTypeDescription}
                </option>
            );
        }, this);

    // Error Checking and throw warnings
    const checkForErrors = (updatedField) => {
        if (!updatedField.companyName) errors.companyName = `Company Name is required`;
        else errors.companyName = null;

        if (!isStateValid(updatedField.state) && updatedField.state) errors.state = `Use valid two letter State`;
        else errors.state = null;

        if (!isEmailValid(updatedField.emailAddress) && updatedField.emailAddress)
            errors.emailAddress = `Email is not valid`;
        else errors.emailAddress = null;

        if (!isPhoneValid(updatedField.phoneNumber) && updatedField.phoneNumber)
            errors.phoneNumber = `Use XXX-XXX-XXXX format`;
        else errors.phoneNumber = null;

        if (!isZipValid(updatedField.zip) && updatedField.zip) errors.zip = `Use 5-digit zip`;
        else errors.zip = null;

        const errorsExist = Object.values(errors).some((e) => Boolean(e));
        props.setButtonDisabled(errorsExist);
    };

    const isEmailValid = (emailAddress) => {
        var pattern = new RegExp(
            /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
        );
        if (!pattern.test(emailAddress)) {
            return false;
        } else return true;
    };

    const isPhoneValid = (phoneNumber) => {
        var pattern = new RegExp(/^\d{3}-\d{3}-\d{4}$/i);
        if (!pattern.test(phoneNumber)) {
            return false;
        } else return true;
    };

    const isZipValid = (zip) => {
        var pattern = new RegExp(/^\d{5}$/i);
        if (!pattern.test(zip)) {
            return false;
        } else return true;
    };

    const isStateValid = (state) => {
        var pattern = new RegExp(
            /^(?:(A[KLRZ]|C[AOT]|D[CE]|FL|GA|HI|I[ADLN]|K[SY]|LA|M[ADEINOST]|N[CDEHJMVY]|O[HKR]|P[AR]|RI|S[CD]|T[NX]|UT|V[AIT]|W[AIVY]))$/i
        );
        if (!pattern.test(state)) {
            return false;
        } else return true;
    };

    return (
        <Form onSubmit={(e) => e.preventDefault()} autoComplete='off'>
            <Row form>
                {Text('3', 'companyName', 'Company Name')}
                {Text('4', 'address', 'Address')}
                {Text('2', 'city', 'City')}
                {Text('1', 'state', 'State')}
                {Text('2', 'zip', 'Zip')}
            </Row>
            <Row form>
                {Text('3', 'firstName', 'First Name')}
                {Text('3', 'lastName', 'Last Name')}
                {Text('2', 'title', 'Title')}
                {Text('2', 'phoneNumber', 'Phone Number')}
                {Text('2', 'emailAddress', 'Email Address')}
            </Row>
            <Row form>
                {Option('2', 'customerType', 'Customer Type', customerTypeList)}
                {Option('2', 'leadSource', 'Lead Source', leadSourcesList)}
                {Check('2', 'safety', 'Safety', setSafetyCheckbox, safetyCheckbox)}
                {Check('2', 'cpr', 'CPR', setCprCheckbox, cprCheckbox)}
                {Check('2', 'sht', 'SHT', setAsCheckbox, asCheckbox)}
                {Check('2', 'as', 'AS', setShtCheckbox, shtCheckbox)}
                {Check('2', 'active', 'Is Active?', setActiveCheckbox, activeCheckbox)}
            </Row>
            <Row form>{Area('12', 'customerNotes', 'Customer Notes', '5')}</Row>
        </Form>
    );
};

export default CustomersForm;
