import React from 'react';
import moment from 'moment';
import { FormGroup, Label, Input, Col, Alert } from 'reactstrap';
import _ from 'lodash';

// Sets Active Checkbox
const handleClick = (props) => {
    props.setCheckbox(!props.checkbox);
    const updated = props.formRow[props.field] === -1 ? 0 : -1;
    updateField({ target: { value: updated } }, props);
};

// Updates a field as you type
const updateField = (e, props) => {
    const value = e.target.value;
    const updatedField = Object.assign({}, props.formRow);
    updatedField[props.field] = value;
    props.setFormRow(updatedField);
    props.checkForErrors(updatedField);
};

const handleOptionSelect = (e, props) => {
    const updatedValue = e.target.value === '0' ? null : e.target.value;
    const updatedFormRow = Object.assign({}, props.formRow);
    const description = _.find(props.list, (l) => {
        return `${l.props.value}` === updatedValue;
    });

    const updatedDescription = description ? description.props.children : '';

    updatedFormRow[props.field + 'Id'] = updatedValue;
    updatedFormRow[props.field + 'Description'] = updatedDescription;

    // TO DO: Deals with tables where fieldName is not like the base table name or using the term description. To fix change SQL tables.
    if (props.field === 'customer') updatedFormRow['companyName'] = updatedDescription;
    if (props.field === 'user') updatedFormRow['userName'] = updatedDescription;
    if (props.field === 'user') updatedFormRow['userName'] = updatedDescription;

    if (props.field === 'activityType' && props.data) {
        let activityType = _.find(props.data.activityTypes, (at) => {
            return at.id == updatedValue;
        });
        updatedFormRow['template'] = activityType ? activityType.template : null;
        updatedFormRow['outboxId'] = activityType ? activityType.outboxId : null;
        updatedFormRow['templateId'] = activityType ? activityType.templateId : null;
    }

    if (props.field === 'template' && props.data) {
        let template = _.find(props.data.templates, (t) => {
            return t.id == updatedValue;
        });
        updatedFormRow['template'] = template ? template.template : null;
    }

    props.setFormRow(updatedFormRow);
    props.checkForErrors(updatedFormRow);
};

const getOptionsValue = (props) => {
    let field = props.field + 'Id';
    let value = props.formRow[field];
    return value || 0;
};

const FormText = (props) => {
    return (
        <Col md={props.colSize}>
            <FormGroup>
                <Label for={props.field}>{props.description}</Label>
                <Input
                    name={props.field}
                    onChange={(e) => updateField(e, props)}
                    value={props.formRow[props.field] || ''}
                    disabled={props.formRow.active === 0}
                />
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

const FormDate = (props) => {
    let fieldDate = props.formRow[props.field] || '';
    return (
        <Col md={props.colSize}>
            <FormGroup>
                <Label for={props.field}>{props.description}</Label>
                <Input
                    type='date'
                    name={props.field}
                    onChange={(e) => updateField(e, props)}
                    value={
                        moment(fieldDate).isValid() && fieldDate.length === 24
                            ? moment.utc(fieldDate).format('YYYY-MM-DD')
                            : fieldDate
                    }
                    disabled={props.formRow.active === 0}
                />
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

const FormCheck = (props) => {
    return (
        <Col md={props.colSize} style={{ paddingTop: '2.4rem', paddingLeft: '2.4rem' }}>
            <FormGroup check>
                <Input
                    type='checkbox'
                    name={props.field}
                    onChange={() => handleClick(props)}
                    defaultChecked={props.checkbox}
                    disabled={props.formRow.active === 0 && props.field !== 'active'}
                />
                <Label for={props.field}>{props.description}</Label>
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

const FormPassword = (props) => {
    return (
        <Col md={props.colSize}>
            <FormGroup>
                <Label for={props.field}>{props.description}</Label>
                <Input
                    type='password'
                    name={props.field}
                    onChange={(e) => updateField(e, props)}
                    value={props.formRow[props.field]}
                    disabled={props.formRow.active === 0}
                />
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

const FormOption = (props) => {
    return (
        <Col md={props.colSize}>
            <FormGroup>
                <Label for={props.field}>{props.description}</Label>
                <Input
                    type='select'
                    name={props.field}
                    id={props.field}
                    onChange={(e) => handleOptionSelect(e, props)}
                    value={getOptionsValue(props)}
                    disabled={props.formRow.active === 0}
                >
                    {props.list}
                </Input>
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

const FormArea = (props) => {
    return (
        <Col md={props.colSize}>
            <FormGroup>
                <Label for={props.field}>{props.description}</Label>
                <Input
                    type='textarea'
                    rows={props.height}
                    name={props.field}
                    onChange={(e) => updateField(e, props)}
                    value={props.formRow[props.field] || ''}
                    disabled={props.formRow.active === 0}
                />
                {props.errors[props.field] && <Alert color='danger'>{props.errors[props.field]}</Alert>}
            </FormGroup>
        </Col>
    );
};

export { FormText, FormDate, FormCheck, FormPassword, FormOption, FormArea };
