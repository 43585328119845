import React from 'react';

function Sidebar({ handlePageChange, activeTab, tabs }) {
  return (
    <div className="sidenav">
        {tabs.map((tab, i) => {
          return <Selector key={i} tab={tab} activeTab={activeTab} handlePageChange={handlePageChange} />
        })}
    </div>
  );
}

function Selector({ handlePageChange, activeTab, tab }) {
  return (
    <div className={activeTab.name === tab.name ? 'selected' : null} onClick={() => handlePageChange(tab)}>{tab.description}</div>
  )
}

export default Sidebar;