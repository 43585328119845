import React from "react";
import { Table } from "reactstrap";
import Grid from "../../components/Grid.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Activity = ( api ) => {
  const selectAPI = 'activity-types-s';
  const updateAPI = 'activity-types-u';
  const primarySort = 'activityTypeDescription';
  const filterableFields = ['activityTypeDescription', 'templateDescription', 'outboxEmail'];
  const module="setup-activity";
  const title="Edit Activity Type";
  const modalSize="wide-modal";

  const editFormObject = (row) => {
    return {
      id: row.id,
      activityTypeDescription: row.activityTypeDescription, 
      templateId: row.templateId,
      templateDescription: row.templateDescription,
      outboxId: row.outboxId,
      outboxDescription: row.outboxDescription,
      active: row.active, 
      new: false,
    }
  }

  const newFormObject = () => {
    return {
      id: null,
      activityTypeDescription: '', 
      templateId: null,
      templateDescription: '',
      outboxId: null,
      outboxDescription: '',
      active: -1, 
      new: true,
    }
  }

  return (
    <Grid 
      api={api} 
      editFormObject={editFormObject} 
      newFormObject={newFormObject} 
      selectAPI={selectAPI}
      updateAPI={updateAPI}
      primarySort={primarySort}
      filterableFields={filterableFields}
      module={module}
      title={title}
      modalSize={modalSize}
    />
  )
}

const ActivityTable = ({ sortBy, rows, toggle }) => {
  return (
    <div className="tableFixHead">
      <Table className="table table-striped" hover>
        <thead>
          <tr>
            <th onClick={() => sortBy('activityTypeDescription', true)}>Activity Type <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('templateDescription', true)}>Template Name <FontAwesomeIcon icon="search" className="mr-2" /></th>
            <th onClick={() => sortBy('outboxDesciption', true)}>Outbox Email <FontAwesomeIcon icon="search" className="mr-2" /></th>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) => {return (
            <tr key={i}>
              <td onClick={() => toggle(row, 'open')}>{row.activityTypeDescription}</td>
              <td onClick={() => toggle(row, 'open')}>{row.templateDescription}</td>
              <td onClick={() => toggle(row, 'open')}>{row.outboxDescription}</td>
            </tr>
          )})}
        </tbody>
      </Table>
    </div>
  )
}

export { Activity, ActivityTable }