import React from 'react';
import moment from 'moment';
import { Table } from 'reactstrap';
import Grid from '../../components/Grid.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { sendEmail } from './activities.options.js';
import { dataPost } from '../../utils/api.js';

const Activities = (api) => {
    const selectAPI = 'activity-s';
    const updateAPI = 'activity-u';
    const primarySort = 'followUpDate';
    const filterableFields = [
        'companyName',
        'firstName',
        'lastName',
        'phoneNumber',
        'emailAddress',
        'userName',
        'activityTypeDescription',
        'customerHistoryNote',
    ];
    const module = 'activities-activities';
    const title = 'Edit Activities';
    const modalSize = 'extra-wide-modal';

    const editFormObject = (row) => {
        return {
            id: row.id,
            customerId: row.customerId,
            companyName: row.companyName,
            userId: row.userId,
            sendText: row.sendText,
            userName: row.userName,
            dateOfActivity: row.dateOfActivity,
            activityTypeId: row.activityTypeId,
            activityTypeDescription: row.activityTypeDescription,
            customerHistoryNote: row.customerHistoryNote,
            followUpRequired: row.followUpRequired,
            sendEmail: row.sendEmail,
            followUpDate: row.followUpDate,
            followUpComplete: row.followUpComplete,
            completedDate: row.completedDate,
            firstName: row.firstName,
            lastName: row.lastName,
            phoneNumber: row.phoneNumber,
            emailAddress: row.emailAddress,
            templateId: row.templateId,
            outboxId: row.outboxId,
            lastEmailSentDate: row.lastEmailSentDate,
            template: row.template,
            new: false,
        };
    };

    const newFormObject = () => {
        return {
            id: null,
            customerId: null,
            companyName: '',
            userId: null,
            userName: '',
            dateOfActivity: moment().format('YYYY-MM-DD'),
            activityTypeId: null,
            activityTypeDescription: '',
            customerHistoryNote: '',
            sendText: 0,
            sendEmail: 0,
            followUpRequired: -1,
            followUpDate: moment().format('YYYY-MM-DD'),
            followUpComplete: '',
            completedDate: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            emailAddress: '',
            templateId: null,
            outboxId: null,
            lastEmailSentDate: null,
            template: '',
            active: -1,
            new: true,
        };
    };

    return (
        <Grid
            api={api}
            editFormObject={editFormObject}
            newFormObject={newFormObject}
            selectAPI={selectAPI}
            updateAPI={updateAPI}
            primarySort={primarySort}
            filterableFields={filterableFields}
            module={module}
            title={title}
            modalSize={modalSize}
        />
    );
};

const dateFormatter = (date) => {
    const newDate =
        date === '0000-00-00 00:00:00.000000'
            ? ''
            : moment(date).isValid() && date.length > 10
            ? moment.utc(date).format('YYYY-MM-DD')
            : date;
    return newDate;
};

const ActivitiesTable = ({ sortBy, rows, toggle, api }) => {
    const sendTestEmail = () => {
        console.log(api);
        const data = dataPost(
            'outbox-send2',
            api,
            {
                email: 'justin@bytrek.com',
                to: 'justin@bytrek.com',
            },

            function (data) {
                console.log(data);
            }
        );
    };
    return (
        <div className='tableFixHead'>
            <Table className='table table-striped' hover>
                <thead>
                    <tr>
                        <th onClick={() => sortBy('companyName', true)}>
                            Company Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('activityTypeDescription', true)}>
                            Activity Type <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('userName', true)}>
                            User <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('firstName', true)}>
                            First Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('lastName', true)}>
                            Last Name <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('phoneNumber', true)}>
                            Phone Number <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('emailAddress', true)}>
                            Email Address <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('customerHistoryNote', true)}>
                            Notes <FontAwesomeIcon icon='search' className='mr-2' />
                        </th>
                        <th onClick={() => sortBy('dateOfActivity', true)}>Date </th>
                        <th onClick={() => sortBy('followUpDate', true)}>Follow Up Date </th>
                        <th>Email Pending</th>
                        <th onClick={() => sortBy('completedDate', true)}>Completed Date </th>
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, i) => {
                        return (
                            <tr key={i}>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.companyName}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.activityTypeDescription}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.userName}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.firstName}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.lastName}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {row.phoneNumber}
                                </td>
                                <td style={{ whiteSpace: 'nowrap' }}>
                                    <a href={`mailto:${row.emailAddress}`}>{row.emailAddress}</a>
                                </td>
                                <td onClick={() => toggle(row, 'open')}>{row.customerHistoryNote}</td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {dateFormatter(row.dateOfActivity)}
                                </td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {dateFormatter(row.followUpDate)}
                                </td>
                                <td>{sendEmail(row) ? 'X' : ''}</td>
                                <td onClick={() => toggle(row, 'open')} style={{ whiteSpace: 'nowrap' }}>
                                    {dateFormatter(row.completedDate)}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export { Activities, ActivitiesTable };
