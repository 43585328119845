import React, { useState } from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dataPost, getPort } from '../utils/api.js';
import _ from 'lodash';

import {
    Container,
    Navbar,
    Nav,
    NavItem,
    NavLink,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from 'reactstrap';

import { useAuth0 } from '@auth0/auth0-react';

const NavBar = ({ handlePageChange, activeTab, token, port, setPort }) => {
    const [userAccess, setUserAccess] = useState({});

    const { user, isAuthenticated, loginWithRedirect, logout } = useAuth0();

    const logoutWithRedirect = () =>
        logout({
            returnTo: window.location.origin,
        });

    if (token && _.isEmpty(userAccess))
        getPort('getport', { token: token }, user, function (result) {
            setPort(result[0].port);
            dataPost('users-s', { token: token, port: result[0].port }, {}, function (result) {
                const activeUser = _.find(result, (r) => r.email.toLowerCase() === user.email.toLowerCase());
                if (activeUser.active === 0) logoutWithRedirect();
                else setUserAccess({ admin: activeUser.admin });
                handlePageChange('activities');
            });
        });

    const isTabActive = (tab) => activeTab === tab;

    return (
        <div>
            <Navbar className='no-padding navbar' color='light' light expand='md'>
                <Container>
                    <Nav className='mr-auto'>
                        {!_.isEmpty(userAccess) && isAuthenticated && (
                            <>
                                <NavItem>
                                    <NavLink
                                        tag={RouterNavLink}
                                        to='/'
                                        onClick={() => handlePageChange('activities')}
                                        activeClassName={isTabActive('activities') ? 'router-link-exact-active' : ''}
                                    >
                                        Activities
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        tag={RouterNavLink}
                                        to='/'
                                        onClick={() => handlePageChange('customers')}
                                        activeClassName={isTabActive('customers') ? 'router-link-exact-active' : ''}
                                    >
                                        Customers
                                    </NavLink>
                                </NavItem>

                                {userAccess.admin === -1 && (
                                    <NavItem>
                                        <NavLink
                                            tag={RouterNavLink}
                                            to='/'
                                            onClick={() => handlePageChange('setup')}
                                            activeClassName={isTabActive('setup') ? 'router-link-exact-active' : ''}
                                        >
                                            Setup
                                        </NavLink>
                                    </NavItem>
                                )}
                            </>
                        )}
                    </Nav>
                    <Nav className='d-none d-md-block' navbar>
                        {!isAuthenticated && (
                            <NavItem>
                                <Button
                                    id='qsLoginBtn'
                                    color='primary'
                                    className='btn-margin'
                                    onClick={() => loginWithRedirect()}
                                >
                                    Log in
                                </Button>
                            </NavItem>
                        )}
                        {!_.isEmpty(userAccess) && isAuthenticated && (
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav caret id='profileDropDown'>
                                    {user.nickname}
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem id='qsLogoutBtn' onClick={() => logoutWithRedirect()}>
                                        <FontAwesomeIcon icon='power-off' className='mr-3' /> Log out
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        )}
                    </Nav>
                </Container>
            </Navbar>
        </div>
    );
};

export default NavBar;
