const dataPost = (state, api, data, callback) => {
    try {
        const url = `https://104.236.229.12:3003/${state}`; // change between 3003 (prod) and 3005 (dev)
        const params = {
            method: 'POST', // or 'PUT'
            headers: {
                Authorization: `Bearer ${api.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                data: data,
                port: api.port,
            }),
        };

        fetch(url, params)
            .then((response) => response.json())
            .then((data) => {
                callback(data);
            })
            .catch((error) => {
                callback(error);
            });
    } catch (error) {
        throw error;
    }
};

const getPort = (state, token, data, callback) => {
    try {
        const url = `https://104.236.229.12:3001/${state}`;
        const params = {
            method: 'POST', // or 'PUT'
            headers: {
                Authorization: `Bearer ${token.token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        };

        fetch(url, params)
            .then((response) => response.json())
            .then((data) => {
                callback(data);
            })
            .catch((error) => {
                callback(error);
            });
    } catch (error) {
        throw error;
    }
};

export { dataPost, getPort };
